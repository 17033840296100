import PermaLink from '../PermaLink/PermaLink'

import './style.scss'

const TitleBorder = ({
  titleText,
  addClass,
}: {
  titleText: string
  addClass?: string
}) => {
  return (
    <h2 className={`title-border ${addClass}`}>
      {titleText}
      <PermaLink title={titleText} />
    </h2>
  )
}

export default TitleBorder
