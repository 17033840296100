import Seo from '../components/seo'
import BuiltDevices from '../components/PluginsPage/BuiltDevices/BuiltDevices'
import PluginsSection from '../components/PluginsPage/PluginsSection/PluginsSection'
import CommunityPlugins from '../components/PluginsPage/CommunityPlugins/CommunityPlugins'
import MainTitle from '../components/MainTitle/MainTitle'
import plugins from '../content/plugins'

const { meta_title, meta_description, meta_image } = plugins

const PluginsPage = () => {
  return (
    <section className="hero-section-padding">
      <div className="section-lg">
        <MainTitle title={plugins.title} addClass="text-center" />
        <h2 className="text-center font-roboto font-light mb-3 text-grey-3 text-xl">
          {plugins.text_1}
        </h2>
        <h2 className="text-center font-roboto font-light mb-5 text-grey-3 text-xl">
          {plugins.text_2}
        </h2>
      </div>

      <div className="section-lg">
        <BuiltDevices />

        <PluginsSection />

        <CommunityPlugins />
      </div>
    </section>
  )
}

export default PluginsPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
