import CardInfo from '../../CardInfo/CardInfo'
import TitleBorder from '../../TitleBorder/TitleBorder'
import plugins from '../../../content/plugins'
import MarkdownText from '../../MarkdownText/MarkdownText'
import './style.scss'

const BuiltDevices = () => {
  return (
    <div className="BuiltInDevices">
      <TitleBorder titleText={plugins.built_devices.title} />

      <MarkdownText text={plugins.built_devices.text} />

      <div className="BuiltInDevices_content">
        {plugins.built_devices.cards.map((item) => {
          return (
            <div className="BuiltInDevices_card" key={item.title}>
              <CardInfo
                description={item.description}
                cardHeaderText={item.title}
                addClass="peachGradient"
                href={item.link}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BuiltDevices
