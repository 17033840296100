import CardInfo from '../../CardInfo/CardInfo'
import TitleBorder from '../../TitleBorder/TitleBorder'
import plugins from '../../../content/plugins'
import MarkdownText from '../../MarkdownText/MarkdownText'
import './style.scss'

const CommunityPlugins = () => {
  return (
    <div className="CommunityPlugins">
      <TitleBorder titleText={plugins.community_plugins.title} />

      <MarkdownText text={plugins.community_plugins.text} />

      <div className="CommunityPlugins_content">
        {plugins.community_plugins.cards.map((item) => {
          return (
            <div className="CommunityPlugins_card" key={item.title}>
              <CardInfo
                description={item.description}
                cardHeaderText={item.title}
                addClass="pinkGradient"
                href={item.link}
                smallerLogo={item.img}
                logoAlt={item.img_alt}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CommunityPlugins
